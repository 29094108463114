export const input: HTMLElement | any = document.getElementById('card-input')
import '../styles/main.scss'
import { status } from './checksum'

/**
 * @param {string} cardNumber
 * @return {*}  {string}
 */
const validateType = (cardNumber: string): void => {
	// American Express: starts with 34/3734
	// VISA: starts with 44
	// MasterCard: starts with 51-55/22-2751
  let iconElement: string = document.getElementById('icon')!.innerHTML

	if (cardNumber.startsWith('3')) {
		iconElement = '<i class="fab fa-cc-amex"></i>'
	} else if (cardNumber.startsWith('4')) {
		iconElement = '<i class="fab fa-cc-visa"></i>'
	} else if (cardNumber.startsWith('5') || cardNumber.startsWith('2')) {
		iconElement = '<i class="fab fa-cc-mastercard"></i>'
	} else {
		iconElement = '<i class="far fa-credit-card"></i>'
	}
}

/**
 * @param {string} size
 * @param {number} validLength
 */
const validateSize = (size: string): boolean => {
  const inputSize: number = size.replace(/ /g, "").length
  const validLength = 16
  let valid = false

  if (inputSize > validLength) {
    input.style.borderColor = "var(--wrongSize)"
    status.style.color = "var(--wrongSize)"
    status.innerText = "Wrong size"
  } else {
    input.style.borderColor = "var(--activeState)"
  }

  switch (inputSize) {
    case validLength:
      // input.style.borderColor = "var(--correctSize)"
      valid = true
      break;
    case 0:
      input.style.borderColor = "var(--neutralInputSize)"
      break;
  }

  return valid
}

export { validateSize, validateType }
