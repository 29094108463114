// @ts-ignore
import 'typeswift'
import { input } from './validate'

export let status = document.getElementById('status')!

/**
 * @param {string} number
 * @return {*}  {string[][]}
 */
function groupCardNumber(number: string): string[][] {
  const arr = number.split('')
  const arrLength = arr.length
  const groupOne = []
  const groupTwo = []

  for (let i = 0; i < arrLength; i++) {
    if ((i % 2) == 0) {
      groupOne.push(arr[i])
    } else {
      groupTwo.push(arr[i])
    }
  }

  return [groupOne, groupTwo]
}

/**
 * @param {string[]} group
 * @return {*}  {number}
 */
function sumGroup(group: string[]): number {
  let sum: number = 0

  Array.from(group, (number: string) => {
    sum += parseInt(number)
  })

  return sum
}

/**
 * @param {string[]} string
 * @return {*}  {string[]}
 */
function fiveOrLarger(string: string[]): string[] {
  return string.filter(element => {
    if (parseInt(element) >= 5) {
      return element
    }
  })
}

/**
 * @param {string} cardNumber
 * @return {*}  {string}
 */
function validateChecksum(cardNumber: string): void {
  const [groupOne, restGroup] = groupCardNumber(cardNumber)

  const sumGroupOne: number = sumGroup(groupOne) * 2
  const restSumGroup: number = sumGroup(restGroup)

  const fiveOrLargerSumLength: number = fiveOrLarger(groupOne).length
  const totalSum: number = sumGroupOne + restSumGroup + fiveOrLargerSumLength

  // @ts-ignore
  const isValid: string = totalSum.toString().split('').last

  // 0 equals valid
  if (isValid === '0') {
    input.style.borderColor = "var(--correctSize)"
    status.style.color = "var(--correctSize)"
    status.innerText = "Valid checksum"
  } else {
    input.style.borderColor = "var(--wrongSize)"
    status.style.color = "var(--wrongSize)"
    status.innerText = "Invalid checksum"
  }
}

export {
  validateChecksum
}
