import { validateSize, validateType } from './scripts/validate'
import { validateChecksum } from './scripts/checksum'
import './styles/main.scss'

document.addEventListener('keyup', (event: Event): void => {
	// @ts-ignore
	const inputValue = event.target.value

	if (inputValue !== undefined) {
		validateType(inputValue)
		
		if (validateSize(inputValue)) {
			validateChecksum(inputValue)
		}
	}
})
